export default {
    initCampaigns(state, campaigns) {
        state.campaigns = campaigns;
    },
    getCampaign(state, campaign) {
        state.campaign = campaign;
    },
    addCampaign(state, campaign) {
        state.campaigns.push(campaign);
    },
    putCampaignBudget(state, budget) {
        state.budget = budget;
    },
    getCampaignBudget(state, words) {
        state.words = words;
    },
    updateCampaign(state, campaign) {
        let index = state.campaigns.findIndex((c) => c.id == campaign.id);
        if (index > -1) {
            state.campaigns[index] = campaign;
        }
    },
    deleteCampaign(state, id) {
        let index = state.campaigns.findIndex((c) => c.id == id);
        if (index > -1) {
            state.campaigns.splice(index, 1);
        }
    }
};