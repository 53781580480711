import Cookies from 'js-cookie';

const CookieToken = Cookies.get("authToken")
const SessionToken = sessionStorage.getItem("authToken")
export const Token = CookieToken ?? SessionToken ?? undefined

export const getHeaders = () => {
    return { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${Token}` } }
}
export const getHeadersUploader = () => {
    return { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${Token}` } }
}
export const authToken = { Authorization: `Bearer ${Token}` }

export const password = {
    min: 6,
    max: 30,
}
