import { message } from "ant-design-vue";
import copy from "copy-to-clipboard";

function customRound(value, round) {
    return value - value % round;
}
function roundDecimal(value) {
    return Math.round((value + Number.EPSILON) * 100) / 100
}
function InfinityNaN(firstValue, secondValue, increaseBool = false) {
    if (isNaN(firstValue / secondValue) || firstValue / secondValue === Infinity)
        return 0
    else {
        if (increaseBool)
            return roundDecimal(firstValue / secondValue * 100)
        else
            return roundDecimal(firstValue / secondValue)
    }
}
function cutText(text, limit = 30) {
    var str = text.slice(0,limit); //например макс 100 символов
    var a = str.split(' ');
    a.splice(a.length-1,1);
    str = a.join(' ');
    return str + ' ...';
  }
function generateImgUrl(nomenclature, size, number = 1) {
    let url = getUrl(Math.floor(nomenclature / 100000));
    return `${url}/vol${Math.floor(nomenclature / 100000)}/part${Math.floor(nomenclature / 1000)}/${nomenclature}/images/${size}/${number}.webp`
}
function getUrl(nomenclature) {
    if (nomenclature >= 0 && nomenclature <= 143)
        return "//basket-01.wbbasket.ru";
    if (nomenclature >= 144 && nomenclature <= 287)
        return "//basket-02.wbbasket.ru";
    if (nomenclature >= 288 && nomenclature <= 431)
        return "//basket-03.wbbasket.ru";
    if (nomenclature >= 432 && nomenclature <= 719)
        return "//basket-04.wbbasket.ru";
    if (nomenclature >= 720 && nomenclature <= 1007)
        return "//basket-05.wbbasket.ru";
    if (nomenclature >= 1008 && nomenclature <= 1061)
        return "//basket-06.wbbasket.ru";
    if (nomenclature >= 1062 && nomenclature <= 1115)
        return "//basket-07.wbbasket.ru";
    if (nomenclature >= 1116 && nomenclature <= 1169)
        return "//basket-08.wbbasket.ru";
    if (nomenclature >= 1170 && nomenclature <= 1313)
        return "//basket-09.wbbasket.ru";
    if (nomenclature >= 1314 && nomenclature <= 1601)
        return "//basket-10.wbbasket.ru";
    if (nomenclature >= 1602 && nomenclature <= 1655)
        return "//basket-11.wbbasket.ru";
    if (nomenclature >= 1656 && nomenclature <= 1919)
        return "//basket-12.wbbasket.ru";
    if (nomenclature >= 1920 && nomenclature <= 2045)
        return "//basket-13.wbbasket.ru";
    if (nomenclature >= 2046 && nomenclature <= 2189)
        return "//basket-14.wbbasket.ru";
    if (nomenclature >= 2190 && nomenclature <= 2405)
        return "//basket-15.wbbasket.ru";
    if (nomenclature >= 2406 && nomenclature <= 2621)
        return "//basket-16.wbbasket.ru";
    if (nomenclature >= 2622 && nomenclature <= 2837)
        return "//basket-17.wbbasket.ru";
    if (nomenclature >= 2838 && nomenclature <= 3053)
        return "//basket-18.wbbasket.ru";
    if (nomenclature >= 3054 && nomenclature <= 3269)
        return "//basket-19.wbbasket.ru";
    if (nomenclature >= 3270 && nomenclature <= 3485)
        return "//basket-20.wbbasket.ru";
    return "//basket-21.wbbasket.ru";
}

const getElementOffset = elem => {
    var top = 0, left = 0
    while (elem) {
      top = top + parseInt(elem.offsetTop)
      left = left + parseInt(elem.offsetLeft)
      elem = elem.offsetParent
    }
  
    return { top: top, left: left }
}

const setJivoContactInfo = (data, timeout = 1000) => {
    //let tryNum = 1
    const interval = setInterval(()=>{
        if (typeof jivo_api === 'undefined'){
            //console.log(`Неудачная попытка: ${tryNum}`)
        } else {
            jivo_api.setContactInfo(data)
            clearInterval(interval)
            //console.log(`Удачная попытка: ${tryNum}`)
        }
        //tryNum++
    }, timeout)
}

const metrics = {
    eventRegister: _ => {
        ym(92925340,'reachGoal','registrachia')
        _tmr.push({ type: 'reachGoal', id: 3340218, value: 1, goal: 'Registracia'})
    }
}

const getDatetime = (separatorCommon = "_", separatorDateTime = "-") => {
    const dt = new Date()
    return dt.getUTCFullYear() + "_" +
                    ("0" + (dt.getUTCMonth()+1)).slice(-2) + separatorCommon +
                    ("0" + dt.getUTCDate()).slice(-2) + separatorDateTime +
                    ("0" + dt.getUTCHours()).slice(-2) + separatorCommon +
                    ("0" + dt.getUTCMinutes()).slice(-2) + separatorCommon +
                    ("0" + dt.getUTCSeconds()).slice(-2);
}

function isNegative(num) {
    if (Math.sign(num) === -1) {
      return true;
    }
    return false;
  }

  const copyToClipboard = (content) => {
    copy(content);
    message.info("Данные скопированы в буфер!");
  }
  const toReplace = (url = false, target = null) => {
    if (url) {
      if(target) 
        window.open(url, target) 
      else 
        window.location.replace(url);
    } else {
      window.location.reload();
    }
  }
  const consoleLog = (...value) => {
    console.log(value);
  }
  // helpers start
const compose = (fn1, fn2) => x => fn1(fn2(x)); 

const reduceByProp = prop => a => 
a.reduce((acc, curr) => ({ ...acc, [curr[prop]]: curr }), {});

const uniqByProp = prop => compose(Object.values, reduceByProp(prop));
// helpers end

// logic start
const uniqByText = uniqByProp('text')
const getUniqAndPrint = compose(console.log, uniqByText)

const fixedRuLocale = {
    name: 'ru',
    weekdays: 'воскресенье_понедельник_вторник_среда_четверг_пятница_суббота'.split('_'),
    weekdaysShort: 'вск_пнд_втр_срд_чтв_птн_сбт'.split('_'),
    weekdaysMin: 'вс_пн_вт_ср_чт_пт_сб'.split('_'),
    months: 'января_февраля_марта_апреля_мая_июня_июля_августа_сентября_октября_ноября_декабря'.split('_'),
    monthsShort: 'янв._февр._марта_апр._мая_июня_июля_авг._сент._окт._нояб._дек.'.split('_'),
    weekStart: 1,
    formats: {
      LT: 'H:mm',
      LTS: 'H:mm:ss',
      L: 'DD.MM.YYYY',
      LL: 'D MMMM YYYY г.',
      LLL: 'D MMMM YYYY г., H:mm',
      LLLL: 'dddd, D MMMM YYYY г., H:mm'
    },
    relativeTime: {
      future: 'через %s',
      past: '%s назад',
      s: 'несколько секунд',
      m: 'минута',
      mm: '%d минут',
      h: 'час',
      hh: '%d часов',
      d: 'день',
      dd: '%d дней',
      M: 'месяц',
      MM: '%d месяцев',
      y: 'год',
      yy: '%d лет'
    },
    ordinal: n => n
  };

export { 
    metrics,
    getDatetime,
    generateImgUrl,
    customRound,
    roundDecimal,
    InfinityNaN,
    getElementOffset,
    setJivoContactInfo,
    isNegative,
    copyToClipboard,
    toReplace,
    consoleLog,
    getUniqAndPrint,
    cutText,
    fixedRuLocale
};