import mutations from './mutations';
import axios from "axios";
import { getHeaders } from '../../../config/auth';

const state = () => ({
    products: [],
    loading: false,
    error: null,
});

const actions = {
    initProducts(context, query = {}) {
        return axios.get(process.env.VUE_APP_API_URL + 'products', {... {...getHeaders() }, ... { params: query } })
        .then((response) => {
                context.commit("initProducts", response.data)
                return response
            }).catch(e => e);
    },
    updateProduct(context, product) {
        return axios
            .patch(process.env.VUE_APP_API_URL + 'products/' + product.id, product, {...getHeaders() })
            .then((response) => {
                context.commit("updateProduct", product);
                return response
            }).catch(e => e);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};