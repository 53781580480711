export default {
    setLoggedIn(state, token) {
        state.loggedIn = token;
    },
    setUserData(state, user) {
        state.userData = user;
    },
    setUserHaveOrg(state, haveOrg) {
        state.haveOrg = haveOrg;
    },
    setUserOrgsCounts(state, orgs_counts) {
        state.orgs_counts = orgs_counts;
    },
    setUserExpiredAt(state, expiredAt) {
        state.expiredAt = expiredAt;
    },
};