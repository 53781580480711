import mutations from './mutations';
import Cookies from 'js-cookie';
import axios from "axios";
import { getHeaders, Token } from '../../../config/auth';
import { setJivoContactInfo, metrics } from "../../../utility/helpers"

const state = () => ({
    loggedIn: !!Token,
    userData: null,
    haveOrg: null,
    orgs_counts: null,
    expiredAt: null,
});

const actions = {
    getUserData({ commit }) {
        return axios.get(process.env.VUE_APP_API_URL + "user", {...getHeaders() })
            .then(response => {
                console.log(response)
                console.log(response.data)
                setTimeout((() => {}),200000)
                commit("setUserData", response.data);
                commit("setLoggedIn", true);
                commit("setUserHaveOrg", response.data.haveOrg ?? false);
                commit("setUserOrgsCounts", response.data.orgs_counts ?? false);
                commit("setUserExpiredAt", response.data.expired_at == null ? null : new Date(response.data.expired_at));
                return response
            }).catch(e => console.log(e));
    },
    updateUserData({ commit }, data) {
        return axios.put(process.env.VUE_APP_API_URL + `users`, data, {...getHeaders() })
            .then(response => {
                commit("setUserData", response.data);
                return response
            })
            .catch((e) => e)
    },
    changeUserPassword({ commit }, data) {
        return axios.put(process.env.VUE_APP_API_URL + `users/change-password`, data, {...getHeaders() })
                    .then(response => response).catch((e) => e)
    },
    sendLoginRequest({ commit }, {password, phone, rememberMe}) {
        return axios.post(process.env.VUE_APP_API_URL + "login", {password, phone})
            .then(response => {
                commit("setUserData", response.data.user);
                if (rememberMe) {
                    Cookies.set("authToken", response.data.token, {expires: 365});
                } else {
                    Cookies.set('authToken', response.data.token)
                }
                commit("setLoggedIn", Boolean(response.data.token));
                setJivoContactInfo({phone})
                return response
            });
    },
    sendRegisterRequest({ commit }, data) {
        return axios.post(process.env.VUE_APP_API_URL + "register", data)
            .then(response => {
                console.log(response)
                const phone = data.phone
                setJivoContactInfo({phone})
                metrics.eventRegister()

                commit("setUserData", response.data);
                commit("setLoggedIn", true);
                Cookies.set("authToken", response.data.token);

                return response;
            })
    },
    sendLogoutRequest({ commit }) {
        commit("setUserData", null);

        Cookies.remove('authToken');
        sessionStorage.removeItem('authToken');
        window.location.href = "/";
    },
};

export default {
    state,
    actions,
    mutations,
};