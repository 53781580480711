export default {
    initProducts(state, products) {
        state.products = products;
    },
    updateProduct(state, product) {
        let index = state.products.findIndex((c) => c.id == product.id);
        if (index > -1) {
            state.products[index] = product;
        }
    }
};