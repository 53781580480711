import mutations from './mutations';
import axios from "axios";
import { getHeaders } from '../../../config/auth';

const state = () => ({
    keywords: [],
    hints: [],
    error: false,
    pending: false,
});

const actions = {
    hints(context, params = {}) {
        context.state.pending = true
        context.state.error = false
        return axios.get(process.env.VUE_APP_API_URL + `keywords/hints`, {... {...getHeaders() }, ... { params } })
            .then((response) => {
                context.commit("hints", response.data.data)
                context.state.pending = false
                context.state.error = false
                return response
            }).catch(e => {
                context.state.error = true
                context.state.pending = false
                return e
            });
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}