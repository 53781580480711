import mutations from './mutations';
import axios from "axios";
import Cookies from 'js-cookie';
import { getHeaders } from '../../../config/auth';

const state = () => ({
    products: [],
    codes: [],
    bids: [],
    type: 'all',
    city: 77,
    keyword: '',
    loading: false,
    error: null,
});

const actions = {
    async searchKeywordCategory(context, { keyword, campaignId, type = 'search', city = 75 } ) {
        return axios
        .get(process.env.VUE_APP_API_URL + `keywords/${type}/${keyword}`, {...getHeaders(), ...{ params: { city, campaignId } } })
        .then((response) => {
            if (response.status == 200) {
                context.commit("updateKeyword", keyword)
                context.commit("updateType", type)
                context.commit("updateRegion", city)
                context.commit("updateProducts", response.data.products)
                context.commit("updateCodes", response.data.codes)
                context.commit("updateBids", response.data.bids)
            }
            context.commit("updateLoading", false)
            return response
        }).catch((e)=>e);
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};