import { createStore } from 'vuex';
import auth from './modules/authentication/actionCreator';
import organization from './modules/organization/actionCreator';
import campaign from './modules/campaign/actionCreator';
import product from './modules/product/actionCreator';
import actualBids from './modules/actualbids/actionSearch';
import keyword from './modules/keyword/actionCreator';

import themeLayout from './modules/themeLayout/actionCreator';
import permission from './modules/permission/actionCreator';
export default createStore({
    modules: {
        auth,
        organization,
        campaign,
        product,
        actualBids,
        keyword,
        
        permission,
        themeLayout,
    },
});