import mutations from './mutations';
import axios from "axios";
import Cookies from 'js-cookie';
import { getHeaders } from '../../../config/auth';

const state = () => ({
    permissions: [],
    loading: false,
    error: null,
});

const actions = {
    initPermissions(context) {
        return axios.get(process.env.VUE_APP_API_URL + 'permissions', {...getHeaders() }).then((response) => {
            context.commit("initPermissions", response.data)
            return response
        }).catch((e) => e);
    },
    addPermission(context, permission) {
        return axios
            .post(process.env.VUE_APP_API_URL + 'permissions', permission, {...getHeaders() })
            .then((response) => {
                if (response.data.info.status == 'success') {
                    context.commit("addPermission", response.data)
                }
                return response;
            });
    },
    updatePermission(context, permission) {
        return axios
            .patch(process.env.VUE_APP_API_URL + 'permissions/' + permission.id, permission, {...getHeaders() })
            .then((response) => {
                context.commit("updatePermission", permission);
            });
    },
    deletePermission(context, id) {
        return axios
            .delete(process.env.VUE_APP_API_URL + 'permissions/' + id, { id }, {...getHeaders() })
            .then((response) => {
                context.commit("deletePermission", id);
            });
    }
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};