import { createWebHistory, createRouter } from 'vue-router';
import store from '@/vuex/store';
import AuthRoutes from './AuthRoutes';
import PageRoutes from './PageRoutes';

const routes = [{
        name: 'Admin',
        path: '/',
        component: () =>
            import ( /* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
        children: [...PageRoutes],
        meta: { auth: true },
    },
    {
        name: 'Auth',
        path: '/auth',
        component: () =>
            import ( /* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
        children: [...AuthRoutes],
    },
    {
        name: 'adminauth',
        path: '/secret/logto/kunzhut',
        component: () =>
            import ( /* webpackChunkName: "secret" */ '@/view/pages/Secret.vue'),
    }
];

const router = createRouter({
    history: createWebHistory(
        process.env.NODE_ENV === 'production' ?
        process.env.VUE_APP_SUB_ROUTE ?
        process.env.VUE_APP_SUB_ROUTE :
        process.env.BASE_URL :
        process.env.BASE_URL,
    ),
    linkExactActiveClass: 'active',
    routes,
});

router.beforeEach((to, from, next) => {
    const isLoggedIn = store.state.auth.loggedIn;  // Проверка авторизации пользователя

    console.log(isLoggedIn)
    
    if (to.path == '/secret/logto') {
        next()
    }

    // Если пользователь авторизован и пытается зайти на страницы /login или /register
    if (isLoggedIn && (to.name === 'login' || to.name === 'register')) {
        console.log("Авторизован, перенаправляем на главную страницу");
        next({ path: '/' });  // Перенаправляем на главную страницу
    }
    // Если пользователь не авторизован и страница требует авторизации
    else if (!isLoggedIn && to.meta.auth) {
        console.log("Не авторизован, перенаправляем на страницу входа");
        next({ name: 'login' });  // Перенаправляем на страницу входа
    }
    // Продолжаем навигацию
    else {
        // Устанавливаем заголовок страницы, если он есть в метаданных маршрута
        const title = to.meta.title;
        const titleFromParams = to.params.pageTitle;

        if (title) {
            document.title = title;  // Устанавливаем заголовок
        }

        if (titleFromParams) {
            document.title = `${titleFromParams} - ${document.title}`;  // Добавляем заголовок из параметров
        }

        next();  // Продолжаем навигацию
    }
});


export default router;